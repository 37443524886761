<template>
  <div class="vice_item" :style="{ width: propsConfig.styles.width }">
    <div class="flex_center vice_title">
      <div class="flex_center vice_title_name">
        <h4>{{propsConfig.title}}</h4>
      </div>
      <div class="vice_title_more" v-if="propsConfig.more" @click="toLink">
        {{propsConfig.more.title}}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "SectionTitle",
    props: {
      propsConfig: {
        type: Object,
        required: true
      }
    },
    methods: {
      toLink() {
        const { url, param } = this.propsConfig.more;
        this.$router.push({path: url, query: {...param}});
      }
    }
  }
</script>

<style scoped lang="less">
  .vice_item {
    border: 1px solid #DCE5F2;
    .vice_title {
      height: 60px;
      justify-content: space-between;
      border-bottom: 1px solid #EDF4FF;
      .vice_title_name {
        position: relative;
        padding-left: 20px;
        > h4 {
          font-size: 20px;
          font-weight: bold;
          color: #21262D;
        }
        > h4:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 10px;
          height: 16px;
          background-color: #007EF2;
        }
      }
      .vice_title_more {
        padding: 0 20px;
        font-size: 16px;
        color: #888888;
        cursor: pointer;
      }
    }
  }
</style>