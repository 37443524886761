<template>
    <div class="main">
        <div class="main_box" v-if="!isSearch">
            <div class="flex main_section">
                <div class="main_section_l">
                    <section-title :propsConfig="sectionTitleConfig" style="margin-bottom: 20px;">
                        <ul class="main_section_l_ul">
                            <li class="flex_center main_section_l_ul_li" v-for="(item, index) in costNorm" :key="item.id" @click="onDetails(item, 'costNorm')">
                                <div class="single_ellipsis main_section_l_ul_li_tag">{{item.city}}</div>
                                <p class="single_ellipsis main_section_l_ul_li_text">{{item.title}}</p>
                            </li>
                            <el-skeleton :rows="5" animated style="padding: 0 20px;" v-if="costNorm.length === 0" />
                        </ul>
                    </section-title>
                    <section-title :propsConfig="specificationConfig" style="margin-bottom: 20px;">
                        <ul class="main_section_l_ul">
                            <li class="flex_center main_section_l_ul_li" v-for="(item, index) in specificationList" :key="index" @click="onDetails(item, 'specificationList')">
                                <div class="single_ellipsis main_section_l_ul_li_tag" style="width: 114px;" :style="item.childType === '部门规章' ? 'color: #20B84E;' : item.childType === '规范性文件' ? 'color: #ED8606;' : item.childType === '地方法规' ? 'color: #5765E3;' : ''">{{item.childType}}</div>
                                <p class="single_ellipsis main_section_l_ul_li_text">{{item.title}}</p>
                            </li>
                            <el-skeleton :rows="5" animated style="padding: 0 20px;" v-if="specificationList.length === 0" />
                        </ul>
                    </section-title>
                    <section-title :propsConfig="costSoftware">
                        <ul class="flex main_section_l_app">
                            <li class="flex_column_center main_section_l_app_item" v-for="(item, index) in softwareList" :key="item.id">
                                <div class="main_section_l_app_item_icon">
                                    <el-image :src="item.icon"></el-image>
                                </div>
                                <p class="more_ellipsis_2">{{item.name}}</p>
                                <div class="main_section_l_app_item_btn" @click="onDetails(item, 'softwareList')">去下载</div>
                            </li>
                        </ul>
                        <el-skeleton :rows="5" animated style="padding: 0 20px;" v-if="softwareList.length === 0" />
                    </section-title>
                </div>
                <div class="main_section_r">
                    <scroll-section :propsConfig="newsConfig" style="margin-bottom: 20px;">
                        <ul class="main_section_r_ul">
                            <li class="main_section_r_ul_li" v-for="(item, index) in newsList" :key="item.id" @click="onDetails(item, '')">
                                <p class="single_ellipsis">{{item.title}}</p>
                            </li>
                        </ul>
                        <el-skeleton :rows="9" animated style="padding: 0 20px;" v-if="newsList.length === 0" />
                    </scroll-section>
                    <scroll-section :propsConfig="localInfoConfig">
                        <ul class="main_section_r_ul">
                            <li class="main_section_r_ul_li" v-for="(item, index) in localInfoList" :key="item.id" @click="onDetails(item, '')">
                                <p class="single_ellipsis">{{item.title}}</p>
                            </li>
                            <el-skeleton :rows="8" animated style="padding: 0 20px;" v-if="newsList.length === 0" />
                        </ul>
                    </scroll-section>
                </div>
            </div>
            <div class="main_section">
                <section-title :propsConfig="trendConfig" style="width: 100%;">
                    <div class="main_cost" v-loading="loading1">
                        <h2 class="main_cost_title">各地住宅建安造价指标对比图（元/㎡）</h2>
                        <div class="flex_row_center main_cost_choose">
                            <div class="main_cost_choose_select">
                                <el-select v-model="hierarchyItem" placeholder="请选择" @change="getCecnNewsGetReportAreaData">
                                    <el-option
                                        v-for="item in hierarchyList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="main_cost_choose_select">
                                <el-select v-model="trendTimeItem" placeholder="请选择" @change="getCecnNewsGetReportAreaData">
                                    <el-option
                                        v-for="item in trendTimeList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div ref="chart" style="width: 100%; height: 500px;"></div>
                    </div>
                </section-title>
            </div>
            <div class="flex main_section">
                <div class="main_section_l">
                    <section-title :propsConfig="quotationConfig" style="margin-bottom: 20px;">
                        <div class="flex main_section_quotation">
                            <el-scrollbar class="main_section_quotation_choose">
                                <div :class="['flex_column_center main_section_quotation_choose_item', quotationTabItem.id === item.id && 'main_section_quotation_choose_item_on']" v-for="(item, index) in quotationTabList" :key="index" @click="onQuotationTab(item)">
                                    <h5 class="main_section_quotation_choose_item_title">{{item.name}}</h5>
                                    <p>{{item.specification}}</p>
                                </div>
                            </el-scrollbar>
                            <div class="main_section_quotation_chart" v-loading="loading2">
                                <div ref="chart1" style="width: 100%; height: 456px;"></div>
                            </div>
                        </div>
                    </section-title>
                    <section-title :propsConfig="newestConfig" style="margin-bottom: 20px;">
                        <el-table
                            @row-click="(e) => onDetails(e, 'periodicalsList')"
                            :data="newestList"
                            v-loading="loading3"
                            style="width: 100%">
                            <el-table-column
                                prop="name"
                                label="材料名称"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                label="规格"
                                width="180">
                                <template slot-scope="scope">
                                    <span>{{scope.row.numbers}}{{scope.row.specification}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="taxCut"
                                label="除税价">
                                <template slot-scope="scope">
                                    <span style="color: #BA9129;">{{scope.row.taxCut}}</span>
                                </template>
                            </el-table-column>
                            <!--<el-table-column
                              prop="address"
                              label="报价地区">
                            </el-table-column>-->
                            <el-table-column
                                label="发布时间">
                                <template slot-scope="scope">
                                    <span>{{scope.row.createTime.split(' ')[0]}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section-title>
                </div>
                <div class="main_section_r">
                    <scroll-section :propsConfig="periodicalsConfig" style="margin-bottom: 20px;">
                        <ul class="main_section_r_ul" style="padding: 0">
                            <li class="flex main_section_r_ul_li" style="border-bottom: 1px solid #EDF4FF; padding: 20px;" v-for="(item, index) in periodicalsList" :key="item.id" @click="onDetails(item, 'periodicalsList')">
                                <p class="single_ellipsis">{{item.title}}</p>
                                <span>{{item.updateTime.split(' ')[0]}}</span>
                            </li>
                            <el-skeleton :rows="10" animated style="padding: 0 20px;" v-if="periodicalsList.length === 0" />
                        </ul>
                    </scroll-section>
                    <scroll-section :propsConfig="enquiryConfig">
                        <ul class="main_section_r_ul">
                            <li class="main_section_r_ul_li" v-for="(item, index) in hotInquiry" :key="index">
                                <p class="single_ellipsis">{{item}}</p>
                            </li>
                            <el-skeleton :rows="10" animated style="padding: 0 20px;" v-if="hotInquiry.length === 0" />
                        </ul>
                    </scroll-section>
                </div>
            </div>
        </div>
        <template v-else-if="tableList.length !== 0">
            <div class="main_table" v-loading="loading">
                <div class="flex_center main_table_item" v-for="(item, index) in tableList" :key="index">
                    <div class="main_table_item_l">
                        <h3 class="main_table_item_l_title" v-html="item.journalName"></h3>
                        <p>地区：{{item.city}}</p>
                        <p>时间：{{item.updateDate || '-'}}</p>
                    </div>
                    <div class="main_table_item_r" @click="onPeriodical(index)">查看详情</div>
                </div>
            </div>
            <div class="flex main_pagination">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="size"
                    layout="total, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </template>
        <template v-else>
            <el-empty :image="require('@/assets/images/bjtsy/no_data.png')" :image-size="400"></el-empty>
        </template>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import cache from "@/libs/util.cache.js";
    import { highlightStringInArray } from '@/utils/public'
    import SectionTitle from './components/SectionTitle'
    import ScrollSection from './components/ScrollSection'
    import EngineeringSearch from './components/EngineeringSearch'
    import { urlCecnNewsPage, urlSoftwareMrgPage, urlCecnNewsGetSubsTime, urlCecnNewsGetSubsType, urlCecnNewsGetReportAreaData, urlProjectPriceInfoEsPriceInfo, urlPriceHotMarket, urlPriceTrend, urlPriceNewPeriodicalList, urlPriceNewestMarketList, urlDcEnterpriseHighPage, urlEnquiryHotCostMaterials } from '@/api/engineering'
import { Alert } from 'element-ui';
    export default {
        name: "engineering",
        components: { SectionTitle, ScrollSection, EngineeringSearch },
        data() {
            return {
                sectionTitleConfig: {
                    title: '造价定额',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: 'quota-list',
                        param: {
                            isTabs: 'sectionTitleConfig',
                            type: '标准定额',
                            childType: '全国统一计价依据'
                        }
                    },
                    styles: {
                        width: '800px'
                    }
                },
                specificationConfig: {
                    title: '造价规范',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: 'quota-list',
                        param: {
                            isTabs: 'specificationConfig',
                            type: '政策法规',
                            childType: '全部'
                        }
                    },
                    styles: {
                        width: '800px'
                    }
                },
                costSoftware:{
                    title: '软件商城',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: '/engineering/costSoftware'
                    },
                    styles: {
                        width: '800px'
                    }
                },
                newsConfig: {
                    title: '新闻',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: 'quota-list',
                        param: {
                            isTabs: 'newsConfig',
                            type: '综合新闻',
                            childType: '综合新闻'
                        }
                    },
                    styles: {
                        height: '345'
                    }
                },
                localInfoConfig: {
                    title: '各地行业信息',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: 'quota-list',
                        param: {
                            isTabs: 'localInfoConfig',
                            type: '各地信息',
                            childType: '各地信息'
                        }
                    },
                    styles: {
                        height: '310'
                    }
                },
                trendConfig: {
                    title: '造价趋势',
                    more: {
                        title: '更多>>',
                        icon: '',
                        url: 'tendency-chart'
                    },
                    styles: {}
                },
                quotationConfig: {
                    title: '热门行情',
                    styles: {}
                },
                newestConfig: {
                    title: '最新行情',
                    styles: {
                        width: '800px'
                    }
                },
                periodicalsConfig: {
                    title: '最新期刊',
                    styles: {
                        height: '456'
                    }
                },
                enquiryConfig: {
                    title: '热门询价',
                    styles: {
                        height: '530'
                    }
                },

                scrollSectionConfig: {
                    title: '新闻资讯',
                    styles: {
                        width: '',
                        backgroundColor: ''
                    }
                },
                option:  {
                    dataset: [
                        {
                            dimensions: ['name', 'age'],
                            source: []
                        },
                        {
                            transform: {
                                type: 'sort',
                                config: { dimension: 'age', order: 'desc' }
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 30 }
                    },
                    yAxis: {},
                    series: {
                        type: 'bar',

                        label: {
                            show: true,
                            position: 'top',
                            valueAnimation: true,
                            fontFamily: 'monospace'
                        }
                    }
                },
                option1:  {
                    title: {
                        text: '近半年价格趋势'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['除税价', '含税价']
                    },
                    grid: {
                        left: '3%',
                        right: '10%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: []
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '除税价',
                            type: 'line',
                            stack: 'Total',
                            data: []
                        },
                        {
                            name: '含税价',
                            type: 'line',
                            stack: 'Total',
                            data: []
                        }
                    ]
                },

                costNorm: [],
                specificationList: [],
                softwareList: [],
                newsList: [],
                localInfoList: [],
                periodicalsList: [],
                newestList: [],
                hotInquiry: [],
                tableList: [],
                currentPage: 1,
                size: 20,
                total: 0,

                trendTimeList: [],
                trendTimeItem: '',
                hierarchyList: [],
                hierarchyItem: '',
                quotationTabList: [],
                quotationTabItem: '',
                loading: false,
                loading1: false,
                loading2: false,
                loading3: false,
                isSearch: false
            }
        },
        async mounted() {
            document.title = '工程咨询-首页' //直接等于需要修改的名字即可
            this.loading1 = true
            await this.getSearch()
            if (this.isSearch) return
            this.getChart = echarts.init(this.$refs.chart)
            this.getChart1 = echarts.init(this.$refs.chart1)
            this.getSoftwareMrgPage()
            this.getPriceHotMarket()
            this.getPriceNewPeriodicalList()
            this.getPriceNewestMarketList()
            this.getEnquiryHotCostMaterials()
            this.costNorm = await this.getCecnNewsPage(`type=标准定额&current=1&size=5&childType=全国统一计价依据`);
            this.specificationList = await this.getCecnNewsPage(`type=政策法规&current=1&size=5&childType=`);
            this.newsList = await this.getCecnNewsPage(`type=综合新闻&current=1&size=9&childType=综合新闻`);
            this.localInfoList = await this.getCecnNewsPage(`type=各地信息&current=1&size=8&childType=各地信息`);

            await this.getCecnNewsGetSubsTime()
            await this.getCecnNewsGetSubsType()
            this.getCecnNewsGetReportAreaData()
        },
        methods: {
            handleCurrentChange(val) {
                this.currentPage = val
                this.getSearch()
            },
            onPeriodical(index) {
                cache.setSessionStorage('infoPrice', this.tableListCope[index]);
                this.$router.push({path: "/info-price/periodical"});
            },
            async getEnquiryHotCostMaterials() {
                const res = await urlEnquiryHotCostMaterials()
                this.hotInquiry = res.data
            },
            async getSearch() {
                this.loading = true
                const { search } = this.$route.query
                if (typeof search !== 'undefined') {
                    this.isSearch = true
                    const param = {
                        current: this.currentPage,
                        name: search,
                        size: this.size
                    }
                    const res = await urlProjectPriceInfoEsPriceInfo(param)
                    const { records, size, total } = res.data
                    this.tableList = highlightStringInArray({data: records, keys: 'journalName'}, search)
                    this.tableListCope = records
                    this.size = size
                    this.total = total
                } else {
                    this.isSearch = false
                }
                this.loading = false
            },
            async getPriceNewestMarketList() {
                this.loading3 = true
                const param = `regionCode=2064`
                const res = await urlPriceNewestMarketList(param)
                this.newestList = res.data
                this.loading3 = false
            },
            async getPriceNewPeriodicalList() {
                const param = `regionCode=2064`
                const res = await urlPriceNewPeriodicalList(param)
                this.periodicalsList = res.data
            },
            onQuotationTab(val) {
                if (this.quotationTabItem.id === val.id) return
                this.quotationTabItem = val
                this.getPriceTrend()
            },
            async getPriceTrend() {
                this.loading2 = true
                const { name, regionCode, specification } = this.quotationTabItem
                const param = `name=${name}&specification=${specification}&regionCode=${regionCode}`
                const res = await urlPriceTrend(param)
                let issueDate = []
                res.data.forEach(item => {
                    issueDate.push(item.issueDate.split(' ')[0])
                    this.option1.series[0].data.push(item.taxCut)
                    this.option1.series[1].data.push(item.taxInclusive)
                })
                this.option1.xAxis.data = issueDate
                this.getChart1.setOption(this.option1)
                this.loading2 = false
            },
            async getPriceHotMarket() {
                const param = `regionCode=2064`
                const res = await urlPriceHotMarket(param)
                this.quotationTabList = res.data
                this.quotationTabItem = res.data?.[0] || []
                this.getPriceTrend()
            },
            onDetails(item, type) {
                switch (type) {
                    case 'costNorm':
                        window.open(item.fileUrl, '_blank')
                        break
                    case 'specificationList':
                        this.$router.push({path: "/engineering/quota-details", query: { detailId: item.detailId, bid: item.id, isTabs: 'specificationList' }});
                        break
                    case 'softwareList':
                        window.open(item.jumpUrl, '_blank');
                        break
                    case 'periodicalsList':
                        this.$router.push({path: "/info-price/details", query: { id: item.id }});
                        break
                    default:
                        this.$router.push({path: "/engineering/quota-details", query: { detailId: item.detailId, bid: item.id, isTabs: '' }});
                        break
                }
            },
            async getCecnNewsGetReportAreaData() {
                this.loading1 = true
                const param = `timeId=${this.trendTimeItem}&typeId=${this.hierarchyItem}`
                const res = await urlCecnNewsGetReportAreaData(param)
                const obj = JSON.parse(res.data.valueJson)
                let arr = []
                obj.name.forEach((item, index) => {
                    arr.push([item, obj.value[index]])
                })
                this.option.dataset[0].source = arr
                this.getChart.setOption(this.option)
                this.loading1 = false
            },
            async getCecnNewsGetSubsType() {
                const param = ``
                const res = await urlCecnNewsGetSubsType(param)
                this.hierarchyList = res.data
                this.hierarchyItem = res.data[0].id
            },
            async getCecnNewsGetSubsTime() {
                const param = ``
                const res = await urlCecnNewsGetSubsTime(param)
                this.trendTimeList = res.data
                this.trendTimeItem = res.data[0].id
            },
            async getSoftwareMrgPage() {
                const param = {
                    current: 1,
                    name: "",
                    size: 6
                }
                const res = await urlSoftwareMrgPage(param)
                const { records } = res.data
                this.softwareList = records
            },
            async getCecnNewsPage(param) {
                const res = await urlCecnNewsPage(param)
                const { records } = res.data
                return records
            }
        }
    }
</script>

<style scoped lang="less">
    .main {
        text-align: left;
        .main_box {
            .main_section {
                padding-bottom: 20px;
                .main_section_l {
                    padding-right: 20px;
                    .main_section_l_ul {
                        padding: 10px 0;
                        .main_section_l_ul_li {
                            height: 36px;
                            overflow: hidden;
                            padding-right: 20px;
                            line-height: 1;
                            font-size: 16px;
                            color: #4E4E4E;
                            cursor: pointer;
                            .main_section_l_ul_li_tag {
                                width: 88px;
                                min-width: 88px;
                                padding-left: 20px;
                                color: #007EF2;
                                text-align: left;
                            }
                            .main_section_l_ul_li_text {
                                flex: 1;
                                text-align: left;
                            }
                        }
                        .main_section_l_ul_li:hover {
                            color: #007EF2;
                        }
                    }
                    .main_section_l_app {
                        padding: 20px 0;
                        justify-content: space-between;
                        .main_section_l_app_item {
                            padding: 0 20px;
                            font-size: 16px;
                            color: #21262D;
                            .main_section_l_app_item_icon {
                                width: 40px;
                                height: 40px;
                                margin-bottom: 10px;
                            }
                            > p {
                                height: 42px;
                            }
                            .main_section_l_app_item_btn {
                                width: 80px;
                                height: 24px;
                                margin-top: 8px;
                                border-radius: 24px;
                                background-color: #EDF4FF;
                                font-size: 14px;
                                color: #007EF2;
                                line-height: 24px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .main_section_r {
                    flex: 1;
                    overflow: hidden;
                    .main_section_r_ul {
                        padding: 10px 0;
                        .main_section_r_ul_li {
                            justify-content: space-between;
                            padding: 10px 20px;
                            font-size: 16px;
                            color: #888888;
                            line-height: 1;
                            text-align: left;
                            cursor: pointer;
                            .single_ellipsis {
                                flex: 1;
                            }
                        }
                        .main_section_r_ul_li:hover {
                            color: #007EF2;
                        }
                    }
                }
                .main_cost {
                    padding: 20px;
                    .main_cost_title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #21262D;
                        padding-bottom: 20px;
                    }
                    .main_cost_choose {
                        padding-bottom: 20px;
                        .main_cost_choose_select {
                            padding: 0 20px;
                        }
                    }
                }
                .main_section_quotation {
                    .main_section_quotation_choose {
                        width: 220px;
                        min-width: 220px;
                        height: 456px;
                        .main_section_quotation_choose_item {
                            width: 220px;
                            min-width: 220px;
                            height: 80px;
                            align-items: flex-start;
                            background-color: #F8F8F8;
                            padding: 0 20px;
                            cursor: pointer;
                            font-size: 14px;
                            color: #888888;
                            line-height: 1.5;
                            .main_section_quotation_choose_item_title {
                                font-size: 16px;
                                font-weight: bold;
                                color: #4E4E4E;
                            }
                        }
                        .main_section_quotation_choose_item_on {
                            background-color: #fff;
                            color: #007EF2;
                            .main_section_quotation_choose_item_title {
                                color: #007EF2;
                            }
                        }
                        /deep/ .el-scrollbar__wrap {
                            height: 456px;
                            overflow-x: hidden;
                        }
                    }
                    .main_section_quotation_chart {
                        width: 100%;
                        height: 456px;
                    }
                }
            }
        }
        .main_table {
            border: 1px solid #DCE5F2;
            .main_table_item {
                padding: 20px;
                justify-content: space-between;
                border-bottom: 1px solid #DCE5F2;
                .main_table_item_l {
                    color: #4E4E4E;
                    .main_table_item_l_title {
                        padding-bottom: 20px;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    > p {
                        font-size: 14px;
                    }
                }
                .main_table_item_r {
                    font-size: 16px;
                    font-weight: 500;
                    color: #1B5C98;
                    cursor: pointer;
                }
            }
        }
        .main_pagination {
            padding-top: 20px;
        }
    }

</style>
