<template>
  <div class="main">
    <div class="main_box">
      <div class="main_total">
        搜索结果共（<strong>{{total}}</strong>）
      </div>
      <div class="main_table">
        <div class="flex_center main_table_item" v-for="(item, index) in dataList" :key="index">
          <div class="main_table_item_l">
<!--            <p>2023年2月12日<em>福建省</em>福州市黑色及有色金属</p>-->
            <p>{{item.title}}</p>
            <div class="main_table_item_l_time">
              <span>{{item.city || item.childType}}</span>
              <span>{{item.time}}</span>
            </div>
          </div>
          <div class="flex_row_center main_table_item_r" @click="onDetails(item)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "engineeringSearch",
    props: {
      dataList: {
        type: Array,
        required: true
      },
      total: {
        type: Number | String,
        required: true
      }
    },
    methods: {
      onDetails(e) {
        this.$emit('onDetails',  e)
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_total {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #212121;
        > strong {
          color: #EE1E1E;
        }
      }
      .main_table {
        border: 1px solid #EDF4FF;
        border-bottom: none;
        .main_table_item {
          height: 90px;
          border-bottom: 1px solid #EDF4FF;
          .main_table_item_l {
            flex: 1;
            padding: 0 20px;
            border-right: 1px solid #EDF4FF;
            line-height: 1;
            > p {
              padding: 10px 0;
              font-size: 16px;
              font-weight: 500;
              color: #4E4E4E;
              > em {
                color: #EE1E1E;
              }
            }
            .main_table_item_l_time {
              padding: 10px 0;
              margin: 0 -10px;
              > span {
                display: inline-block;
                padding: 0 10px;
                font-size: 14px;
                color: #4E4E4E;
              }
            }
          }
          .main_table_item_r {
            width: 120px;
            min-width: 120px;
            font-size: 16px;
            font-weight: 500;
            color: #1B5C98;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>