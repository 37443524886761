<template>
  <div>
    <div class="flex_center vice_title">
      <h4>{{propsConfig.title}}</h4>
      <div class="vice_title_more" v-if="propsConfig.more" @click="toLink">
        {{propsConfig.more.title}}
      </div>
    </div>
    <el-scrollbar class="vice_scroll" :style="{height: propsConfig.styles && propsConfig.styles.height + 'px'}">
      <slot></slot>
    </el-scrollbar>
  </div>
</template>

<script>
  export default {
    name: "ScrollSection",
    props: {
      propsConfig: {
        type: Object,
        required: true
      }
    },
    methods: {
      toLink() {
        const { url, param } = this.propsConfig.more;
        this.$router.push({path: url, query: { ...param }});
      }
    }
  }
</script>

<style scoped lang="less">
  .vice_title {
    height: 60px;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #EDF4FF;
    > h4 {
      font-size: 20px;
      font-weight: bold;
      color: #21262D;
    }
    .vice_title_more {
      font-size: 16px;
      color: #888888;
      cursor: pointer;
    }
  }
  .vice_scroll {
    height: 340px;
    border: 1px solid #DCE5F2;
    border-top: none;
    /deep/ .el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
    }
  }
</style>